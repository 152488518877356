.bannerwrapper {
  background-color: #7b2bff;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px 0;
  overflow: hidden;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  position: relative;
  .bannerDetails {
    display: flex;
    align-items: center;
    > div:nth-child(1) {
      width: 70%;
      > p {
        margin: 0;
      }
      > p:nth-child(1) {
        font-size: 16px;
        color: white;
        font-family: "Sofia-ProSoft-Regular";
      }
      > p:nth-child(2) {
        font-size: 8px;
        color: white;
        font-family: "Sofia-ProSoft-Regular";
      }
    }
    > div:nth-child(2) {
      width: 30%;
      text-align: center;
    }
  }
  .clock {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 45%;
  }
  .bg {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0%;
  }
  .expire {
    margin-top: 10px;
    display: flex;
    z-index: 2;
    align-items: center;
    > div:nth-child(1) {
      width: 70%;
      z-index: 2;
      > div.copy {
        padding: 6px 10px;
        width: fit-content;
        background: rgba(0, 0, 0, 0.2);
        // backdrop-filter: blur(25px);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          font-family: "Sofia-ProSoft-Regular";
          font-size: 13px;
          padding: 2px 5px 2px 5px;
          color: white;
        }
      }
    }
    > div:nth-child(2) {
      padding: 5px 10px;
      width: 30%;
      text-align: center;
      background-color: white;
      border-radius: 5px;
      z-index: 2;
      > p {
        margin: 0;
        font-weight: 500;
        font-family: "Sofia-ProSoft-Regular";
        font-size: 13px;
      }
    }
  }
}
