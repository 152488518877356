.modalHeader{
    text-align: center;
    >div.info{
        display: flex;
        justify-content: space-between;
        >span:nth-child(1){
            width: 45%;
            text-align: end;
        }
        >span:nth-child(2){
            width: 45%;
            text-align: left;
        }
     }
     >div.button{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
     }
}