.preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff85;
  text-align: center;
  z-index: 2000;

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}
