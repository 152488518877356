.selfHelpContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-width: 768px;
  background: #f5f5f5;
  margin: auto;
}

.selfHelpContainer::-webkit-scrollbar {
  display: none;
}

.modify {
  display: flex;
  padding: 8px 7px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  cursor: pointer;
  border-radius: 8px;
  background: rgba(123, 42, 255, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.editBooking {
  position: fixed;
  width: 100%;
  max-width: 768px;
  border-radius: 20px 20px 0px 0px;
  bottom: 0;
  background: white;
  z-index: 2;
  padding-bottom: 20px;
  > .header {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    > img {
      cursor: pointer;
    }
  }
  > .selectable {
    cursor: pointer;
    display: flex;
    padding: 12px 16px 8px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    > div {
      display: flex;
      gap: 5px;
    }
  }
}
.contentContainer {
  margin-top: 0rem;
}
// .tracking {
//   position: fixed;
//   width: 100%;
//   z-index: 11;
//   max-width: 768px;
//   padding: 5px;
//   font-size: 16px;
//   text-align: center;
//   color: white;
//   background-color: #7b2aff;
// }
.scrollUP {
  height: 40px;
  width: 40px;
  line-height: 30px;
  border-radius: 20px;
  background-color: #7b2bff;
  color: white;
  text-align: center;
}
.arrowG {
  opacity: 0;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.arrow {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}

.arrow span {
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #7b2bff;
  border-right: 2px solid #7b2bff;
  transform: rotate(225deg);
  margin: -10px;
  animation: cssAnimation 02s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}
@keyframes cssAnimation {
  0% {
    opacity: 0;
    transform: rotate(225deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(225deg) translate(20px, 20px);
  }
}

.busNotStartedInfo {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: red;
  border-radius: 15px 15px 0px 0px;
  font-size: 13px;
  max-width: 768px;
  color: white;
  padding: 15px 7px;
  font-family: "Sofia-ProSoft-Regular";
  text-align: center;
  z-index: 9;
}
.tourstyle {
  background: rgba(0, 0, 0, 0.8);
  height: 1160px;
  max-width: 768px;
  position: absolute;
  z-index: 10;
  width: 100%;
}
.marketingCardsData {
  // margin: 2%;
  background: white;
  border-radius: 5px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  // margin-left: auto;

  > img {
    width: 100%;
    height: 100%;
  }

  &:not(:first-child) {
    margin-top: 10px;
    width: 100%;
  }

  > * {
    font-family: "Sofia-ProSoft-Regular";
  }
}

.trayCards {
  background: white;
  border-radius: 5px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  // margin-left: auto;

  > img {
    width: 100%;
    height: 100%;
  }

  &:not(:first-child) {
    margin-top: 10px;
    width: 100%;
  }

  > * {
    font-family: "Sofia-ProSoft-Regular";
  }
}
.qrCode {
  background: white;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0px #0000004f;
  padding-right: 15px;
  width: fit-content;
  padding-left: 15px;
  margin-right: auto;
  // margin-left: auto;

  > img {
    width: 100%;
    height: 100%;
  }

  &:not(:first-child) {
    margin-top: 10px;
    width: 100%;
  }

  > * {
    font-family: "Sofia-ProSoft-Regular";
  }
}

.transparentBackground {
  background: transparent !important;
}
.SOSModal {
  text-align: center;
  > * {
    font-family: "Sofia-ProSoft-Regular";
  }
  > p:nth-child(1) {
    color: #292929;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  > p {
    color: #454545;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > div {
    padding: 15px 0px;
    border-radius: 10px;
    background: #7b2aff;
    > span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
