.loginDiv {
  .mainBody {
    background-image: url(../../images/bg-banner.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
  }

  .container {
    padding: 40px 190px;
    width: 100%;
    height: 100vh;
  }

  .loginBlock {
    background: white;
    min-height: 390px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding-top: 70px;
    padding-left: 45px;
    padding-right: 45px;
    height: 100%;
  }

  .bgLogin {
    background-image: url(../../images/login.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    border-radius: 20px;
  }

  .ph-cta {
    button {
      border: none;
      background: none;
    }
    i {
      font-size: 9px;
      float: right;
      padding: 6px;
    }
  }

  label {
    display: none;
  }

  input {
    height: 47px;
    width: 100%;
    border-radius: 9px;
    border: solid 1px #eaeaea;
    padding-left: 10px;
    outline: none;
    &:focus-within {
      box-shadow: #d9d1e6 0 0 15px 0px;
      border: 1px solid rgba(89, 0, 234, 0.45);
    }
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    font-size: 20px;
    font-family: "Sofia-ProSoft-Regular";
    font-weight: 700;
  }

  .input {
    position: relative;
    img {
      margin: auto;
      position: absolute;
      top: 0;
      left: 15px;
      bottom: 0;
    }
    span {
      margin: auto;
      position: absolute;
      top: 0.35em;
      left: 55px;
      display: inline-table;
      font-size: 17px;
    }
    input {
      padding-left: 90px;
      font-size: 20px;
      font-family: "Sofia-ProSoft-Regular";
      font-weight: 700;
    }
  }

  .resend-btn {
    border: none;
    background: none;
    font-family: "Sofia-ProSoft-Regular";
    font-weight: 700;
    margin-top: 10px;
    color: #ff5f97 !important;
  }

  h1 {
    font-family: Mont-ExtraLightDEMO, sans-serif;
    font-size: 30px;
    margin: 0;
    letter-spacing: -0.79px;
    font-weight: 700;
  }
}

@media screen and (max-width: 767px) {
  .loginDiv {
    .container {
      padding: 120px 30px;
      width: auto;
      padding-top: 60px;
    }

    .loginBlock {
      min-height: 300px;
      max-height: 300px;
      padding-top: 25px;
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 20px;
    }
  }
}
