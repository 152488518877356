@media  screen   
and (min-width: 1025px)   
{
.booking_information_table {
  :global {
      .ant-table-thead > tr:hover > th,
      .ant-table-tbody > tr:hover > td {
          padding: 10 0 !important;
          background-color:transparent;
          width: fit-content;
          color: white;
          // height: 100vh;
          // position: absolute;
          font-size: 35px;
           font-family: SF-Pro-Bold;
          // border-bottom: 2px solid #FF8888;  
          overflow-wrap: break-word;
          
      }
      
      .ant-table-container table > thead > tr th,.ant-table-container table > thead:hover > tr th {
        background: #7B2AFF;
        color: white;
        font-size: 28px;
        height: 3rem;
        padding: 1.5%;
        font-family: SF-Pro-Bold;
        border-top-left-radius: 2px;
      }
      .ant-table-pagination.ant-pagination{
        margin: 16px 0;
        display: none;
      }
      
}
}
.table_row_light {
  background-color: #F75151;
  padding: 1000px  !important;
  width: fit-content;
  // padding: 200px;
  color: white;
  height: 7rem;
  font-size: 35px;
   font-family: SF-Pro-Bold;
  border-bottom: 2px solid #FF8888;
  overflow-wrap: break-word;
}
.table_row_dark {
  background-color: #270066;
  padding: 1000px  !important;
  width: fit-content;
  color: white;
  height: 7rem;
  font-size: 35px;
  font-family: SF-Pro-Bold;
  border-bottom: 2px solid #FF8888;
  overflow-wrap: break-word;
}

.webHeaderImageContainer {
  float: left;
}
.webHeadertvContainer > span {
  color: rgb(123, 42, 255);
  font-size: 30px;
  padding-right: 10x;
  cursor: pointer;
}
.webHeaderImageContainer > a> img{
  float: left;
  padding: 2%;
  width: 10rem;
  margin-right: 30px;
}
}
// @media screen and (max-width: 2000px)
@media only screen   
and (min-width: 1030px)   
and (max-width: 1366px)    {
  .booking_information_table {
    :global {
        .ant-table-thead > tr:hover > th,
        .ant-table-tbody > tr:hover > td {
            padding: 10 0 !important;
            background-color:transparent;
            width: fit-content;
            color: white;
            height: fit-content;
            font-size: 20px;
            font-family: SF-Pro-Bold;
            // border-bottom: 2px solid #FF8888;  
            overflow-wrap: break-word;
        }
        
        .ant-table-container table > thead > tr th,.ant-table-container table > thead:hover > tr th {
          background: #7B2AFF;
          color: white;
          font-family: SF-Pro-Bold;
          height: 10px;
          font-size: 20px;
          border-top-left-radius: 2px;
        }
        .ant-table-pagination.ant-pagination{
          margin: 16px 0;
          display: none;
        }
        
  }
  }
  .table_row_light {
    background-color: #F75151;
    padding: 1000px  !important;
    width: fit-content;
    // padding: 200px;
    color: white;
    height: fit-content;
    font-size: 20px;
    font-family: SF-Pro-Bold;
    border-bottom: 2px solid #FF8888;
    overflow-wrap: break-word;
  }
  .table_row_dark {
    background-color: #270066;
    padding: 1000px  !important;
    width: fit-content;
    color: white;
    height: fit-content;
    font-size: 20px;
    font-family: SF-Pro-Bold;
    border-bottom: 2px solid #FF8888;
    overflow-wrap: break-word;
  }
  .webHeadertvContainer > span {
    color: rgb(123, 42, 255);
    font-size: 20px;
    font-family: SF-Pro-Bold;
    padding-right: 10x;
    cursor: pointer;
  }
  .webHeaderImageContainer > a> img{
    float: left;
    padding: 5%;
    width: 200px;
    margin-right: 10px;
  }
  
}