@import "~antd/dist/antd.css";

body {
  color: #262628 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}
/* .ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
} */
/* .ant-tabs-nav-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 80vh;
  scrollbar-width: none; 
}

.ant-tabs-nav-list::-webkit-scrollbar {
  display: none; 
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ant-table table {
  width: 100%;
  min-width: 120vh;
  text-align: center;
  border-radius: 4px 4px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
}

.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 1000;
  word-break: break-word;
  min-width: 20vh;
  text-align: center;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
}

.modalClass .ant-modal-content {
  min-width: 100vh;
}

.App-link {
  color: #09d3ac;
}

/* fonts */
@font-face {
  font-family: Mont-ExtraLightDEMO;
  src: url("fonts/Mont-ExtraLightDEMO.otf") format("opentype");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("fonts/Montserrat-Bold.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("fonts/Montserrat-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat-Regular;
  src: url("fonts/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat-Light;
  src: url("fonts/Montserrat-Light.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat-ExtraLight;
  src: url("fonts/Montserrat-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url("fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Sofia-ProSoft-Regular";
  src: url("fonts/Sofia-ProSoft-Regular.ttf") format("truetype");
}

@font-face {
  font-family: SF-Pro-Bold;
  src: url("fonts/FontsFree-Net-SFProText-Bold.ttf") format("truetype");
}

@font-face {
  font-family: SFProText;
  src: url("fonts/SFProText-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Poppins;
  src: url("fonts/Poppins-Regular.ttf") format("truetype");
}

.h1 {
  font-size: 16px;
  color: #262628 !important;
}
h2,
.h2 {
  font-size: 13px;
}
h3,
.h3 {
  font-size: 11px;
}
h4,
.h4 {
  font-size: 10px;
}
p {
  font-size: 12px;
  font-family: "Montserrat-Medium";
}

.bold,
b {
  font-family: "Montserrat-Bold" !important;
}

.semi-bold {
  font-family: "Montserrat-SemiBold" !important;
}

.medium {
  font-family: "Montserrat-Medium";
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4,
ul,
div,
li {
  font-family: "Sofia-ProSoft-Regular";
}

tr,
td {
  word-break: break-all;
  overflow-wrap: break-word;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.tableContent {
  overflow-wrap: break-word;
  white-space: normal;
}

.large {
  font-size: 18px;
}
.transparent_btn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

hr {
  margin: 0;
}

/* color */
.dark-gray {
  color: #797979;
}

.light-gray {
  color: #bec2ce;
}

.light-black {
  color: #585858;
}

.blue {
  color: #7d00ff;
}

.red {
  color: #fa2222;
}

.green {
  color: #35e9af;
}

.light-blue {
  color: #3c78ff;
}

button:focus {
  outline: none !important;
  outline: 0px;
}

textarea {
  font-family: "Montserrat-Medium";
  font-size: 12px;
}

.inputSkeleton {
  background-color: #f4f4f4;
  width: 100px;
  height: 1rem;
  border-radius: 3px;
}

.btn-secondry {
  color: #fff !important;
  background-color: #7b2aff !important;
  cursor: pointer;
  padding: 10px 30px;
  border: none;
  border-radius: 15px;
  -webkit-appearance: none;
  font-family: "Montserrat-Regular", sans-serif;
}

.btn-secondry:disabled {
  opacity: 0.7;
  color: #fff !important;
  background-color: #7824ff !important;
  border: none;
}

.btn-secondry:hover {
  color: white !important;
  background-color: #6d17f9 !important;
}

.btn-secondry.selected {
  background-color: #fff !important;
  color: #7b2aff !important;
  border: 2px solid;
  padding: 8px 30px;
  font-family: "Montserrat-Regular", sans-serif;
  font-weight: 700;
}

.btn-primary {
  color: #fff !important;
  background-color: #ff5f97 !important;
  cursor: pointer;
  padding: 10px 30px;
  border: none;
  border-radius: 15px;
  -webkit-appearance: none;
  font-family: "Montserrat-Regular", sans-serif;
}

.btn-primary:disabled {
  opacity: 0.7;
  color: #fff !important;
  background-color: #ff5f97 !important;
  border: none;
}

.btn-primary:hover {
  color: white !important;
  background: #ff4284 !important;
}

.btn-primary.selected {
  background-color: #fff !important;
  color: #ff5f97 !important;
  border: 2px solid;
  font-family: "Montserrat-Regular", sans-serif;
  font-weight: 700;
}

button.currentLocation {
  position: absolute;
  top: 42vh;
  width: 45px;
  height: 45px;
  right: 15px;
  /* z-index: 1; */
  border: none;
  background: white;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 2px 1px 6px #00000080;
}

button.oldcurrentLocation {
  position: absolute;
  top: 38vh;
  width: 45px;
  height: 45px;
  right: 15px;
  /* z-index: 1; */
  border: none;
  background: white;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 2px 1px 6px #00000080;
}

button.activeLocation {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
}

button.completedLocation {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  /* background-image: linear-gradient(#D6C1F8, ); */
  background-image: linear-gradient(
    179.93deg,
    #d6c1f8 0.06%,
    rgba(214, 193, 248, 0) 116.81%
  );
}

button.assignedLocation {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  /* background-image: linear-gradient(#D6C1F8, ); */
  background-color: #ececec;
}

button.currentLocation > img {
  width: 25px;
}

button.fullscreen > img {
  padding: 3px;
  width: 25px;
}
.labelFamily {
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  z-index: 10;
  color: black;
  text-align: center;
  font-family: "SFPROText";
  font-size: 10px;
  font-weight: 700;
}

.labelFamilyMins {
  background-color: #fff;
  border-radius: 50%;
  padding: 10px 5px 10px 5px;
  z-index: 10;
  color: black;
  text-align: center;
  font-family: "SFPROText";
  font-size: 10px;
  font-weight: 700;
}

.infoWindowBoardingHeader {
  font-size: 12px;
  font-weight: 600;
  font-family: "SFPROTEXT";
  color: #15c25a;
  margin-bottom: 4px;
}

.infoWindowDropHeader {
  font-size: 12px;
  font-weight: 600;
  font-family: "SFPROTEXT";
  color: #e93f3c;
  margin-bottom: 4px;
}

.infoWindowContent {
  font-family: "SFPROTEXT";
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
}

.infoContainer {
  width: 100%;
  background-color: #7b2aff;
  text-align: center;
  color: white;
  padding: 5px 30px;
  font-family: "Montserrat-Regular", sans-serif;
  font-weight: 700;
}

.checkbox {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin-bottom: 12px;
  padding-left: 35px;
  position: relative;
  user-select: none;
}
.checkbox input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
.checkbox input:checked ~ .checkmark {
  background-color: #7b2aff;
  border: none;
  box-shadow: #cdcdcd 0 5px 15px 0;
}
.checkbox .checkmark {
  border-radius: 5px;
  border: 1px solid #c3c6d1;
  height: 25px;
  left: 0;
  position: absolute;
  top: 0;
  width: 25px;
}
.checkbox input:checked ~ .checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 9px;
  top: 5px;
  width: 6px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/* .checkbox:hover input~.checkmark {
  background-color: #ffffff;
} */

.checkbox-span {
  font-family: "Sofia-ProSoft-Regular", sans-serif;
  font-size: 14px;
  margin-left: 5px;
}

.error {
  color: red;
}

.callTrigger {
  align-items: center;
  justify-content: space-evenly;
  display: flex;
}
.searchForm {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.homeSearchForm {
  flex-basis: 75%;
}
.taskSearchForm {
  overflow-y: scroll;
  display: flex;
  padding: 1% 0;
}
.switch_tabs {
  flex-basis: 100%;
  margin: 0.5rem;
}
@media screen and (min-width: 768px) {
  .pnrSearch {
    flex-basis: 20%;
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
    > .pnr_select {
      width: 30%;
      margin: 0 0.2rem;
    }
    > .search_input {
      min-width: 50px;
      width: 20%;
      margin: 0 0.2rem;
    }
    > .search_input_trip {
      width: 50%;
    }
  }
}
.pnrSearchOutbound {
  display: flex;
  flex-basis: 20%;
  flex-direction: row;
}
.userType {
  align-items: center;
  flex-basis: 25%;
  margin: 0.5rem;
}
.dateSearch {
  margin: 0.5rem;
  flex-basis: 32%;
}
.createNewTask {
  align-items: center;
  margin: 0.5rem;
}
.taskSearch {
  margin: 0.5rem;
  display: flex;
}

.tripSearch {
  display: flex;
  gap: 5px;
  margin: 0.5rem;
  flex-basis: 50%;
}
.citySelect {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.liveView {
  flex-basis: 5%;
  margin: 0.5rem;
}
.waiting_lounge {
  flex-direction: column-reverse;
  gap: 5px;
  align-items: flex-end;

  flex-basis: 25%;
  display: flex;
  margin: 0.5rem;
}

.bookingListFooter {
  display: flex;
  justify-content: flex-end;
}
.bookingListFooterItemTwo {
  margin-right: 10px;
}

.bookingListFooterItemOne {
  margin-right: 10px;
}

.bookingListFooterItem2 {
  margin-left: 5px;
}
.carousel .thumbs-wrapper {
  margin: 0;
  overflow: hidden;
}
.ant-modal-body {
  /* padding: 24px; */
  font-size: 14px;
  word-wrap: break-word;
}
header {
  width: 100%;
  z-index: 1000;
  margin: 10px 0px;
}

.ant-tabs-left
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane,
.ant-tabs-left
  > div
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  padding-left: 0px;
}

#webHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1% 0 0;
}

.webHeaderImageContainer {
  float: left;
}

.webHeaderOptionContainer {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: rgba(221, 128, 14, 0.1);
  > span {
    color: #dd800e;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.webHeadertvContainer > span {
  color: rgb(123, 42, 255);
  font-size: 50px;
  padding-right: 10x;
  cursor: pointer;
}
.ant-pagination-options {
  visibility: hidden;
}
.swapButton {
  border: solid 0.5px #e8dbff;
  background: #ffffff;
  border-radius: 50%;
  z-index: 1;
  width: 35px;
  height: 30px;
  margin: unset;
}
.menuBtn {
  border: none;
  background: #ffffff;
  border-radius: 50%;
  z-index: 1;
  width: 2px;
  height: 3px;
}
.carousel .control-next.control-arrow:before {
  border-left: 13px solid black;
}
.carousel .control-prev.control-arrow:before {
  border-right: 13px solid black;
}
.carousel .control-dots .dot {
  transition: opacity 0.25s ease-in;
  opacity: 0.3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
  background: black;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}
.border-right {
  border-right: 1px dotted #b2b2b2 !important;
}
.pnrRow {
  display: flex;
}

.pnrColumn {
  display: flex;
  flex-direction: column;
}

.complainSelector {
  margin: 0 1%;
  display: flex;
  width: 25%;
}

.feedSelector {
  margin: 0 1%;
  display: flex;
  width: 40%;
}

@media screen and (max-width: 768px) {
  span.checkmark {
    width: 20px !important;
    height: 20px !important;
  }

  h1 {
    font-size: 25px;
  }

  .searchForm {
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .pnrSearch {
    width: 100%;
    flex-direction: column;
    gap: 5px;
    > .pnr_select {
      width: 100%;
    }
    > .search_input {
      min-width: 50px;
      width: 100%;
      margin: 0 0.2rem;
    }
    > .search_input_trip {
      width: 100%;
    }
  }

  .tripSearch {
    margin: 0.5rem;
    flex-basis: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
    > .pnr_select {
      width: 100%;
    }
    > .search_input {
      min-width: 50px;
      width: 100%;
      margin: 0 0.2rem;
    }
    > .search_input_trip {
      width: 100%;
    }
  }

  .waiting_lounge {
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin: 0.5rem;
  }

  .citySelect {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .optionSelect {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: flex-start;
  }

  .callTrigger {
    justify-content: center;
    flex-direction: column;
    display: flex;
    padding: 0 0 1% 0;
  }

  .pnrSearchOutbound {
    display: flex;
    flex-basis: 20%;
    flex-direction: column;
    margin-bottom: 5px;
  }

  .complainSelector {
    margin: 0 1%;
    display: flex;
    width: 90%;
  }

  .feedSelector {
    margin: 0 1%;
    display: flex;
    width: 70%;
  }

  .checkbox input:checked ~ .checkmark:after {
    left: 9px;
    top: 4px;
    width: 4px;
    height: 9px;
  }

  .checkbox {
    padding-left: 25px;
  }

  .otpInput {
    width: 2rem !important;
    height: 2rem;
    margin: 0 1rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #d6c1f8;
    box-shadow: 0px 1px 4px 0px #d6c1f8;
  }
}

.pnrTable {
  border: 1px solid black;
  border-collapse: collapse;
}

.otpContainer {
  margin: 5% auto;
  text-align: center;
}

.otpInput {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #d6c1f8;
  box-shadow: 0px 1px 4px 0px #d6c1f8;
}
.selfHelpModalBPDP {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.pnrTable td,
.pnrTable th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
  text-align: left;
}

/* for agreementForm  */
/* body b {
  font-size: 21px;
  margin: 34px 0;
} */
body p,
body li,
body lo {
  font-size: 15px;
  line-height: 21px;
}
