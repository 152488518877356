.timelineTile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .imageContainer {
    padding-right: 20px;
    padding-left: 20px;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .stationInfo {
    width: 50%;
    min-width: 110px;
    flex-grow: 2;

    .stationName {
      display: flex;
      justify-content: space-between;
    }

    .stationAddress {
      font-size: 0.8rem;
      font-family: "Montserrat-Regular", sans-serif;
      overflow-wrap: break-word;
    }

    .viewOnMap {
      color: #7b2aff;
      font-family: "Montserrat-Regular", sans-serif;
      font-size: 0.8rem;
    }
  }

  .time {
    min-width: 80px;
    font-size: 1rem;
  }
}

.hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 90%;
}
