.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  margin-right: 10px;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-left-color: white;
  animation: spin 0.8s linear infinite;
}

.spinnerSecondary {
  border: 3px solid rgba(123, 42, 255, 0.5) !important;
  border-left-color: rgba(123, 42, 255) !important;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
