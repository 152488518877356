.kycPolicyContainer {
  padding: 10px;

  h1 {
    font-size: 26px;
    font-weight: 600;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  hr {
    margin: 16px 0;
    border-top: 1px solid black;
  }
}
