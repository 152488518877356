.overlayContainer {
    width: 100%;
    min-height: calc(100vh - 5rem);
    height: 100%;
  background: white;
    .header {
      width: 100%;
      font-family: "SFPROTEXT";
      color: #292929;
      font-size: 16px;
      background: white;
      padding: 10px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
      button {
        position: absolute;
        background: transparent;
        border: 0px;
      }
  
      span {
        font-family: "SFPROTEXT";
        font-size: 1.2rem;
      }
    }
  
    .content {
      width: 100%;
      min-height: calc(100% - 30px);
    }
  }
  