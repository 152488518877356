.selfHelpContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  max-width: 768px;
  background: #f5f5f5;

  & > * {
    font-family: "Sofia-ProSoft-Regular";
  }

  h1 {
    font-size: 20px;
    line-height: 24px;
    margin: 0;
  }

  h2 {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
  }

  .header {
    padding: 14px 20px;
    background: #1c1c1c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      overflow: hidden;
      color: #fff;
      font-family: "Sofia-ProSoft-Regular";
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    > .sos {
      border-radius: 300px;
      background: #d21923;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 5px;
        color: #fff;
        font-family: "Sofia-ProSoft-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    > .sosInitiated {
      border-radius: 300px;
      background: #292929;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 5px;
        color: #fff;
        font-family: "Sofia-ProSoft-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .contentContainer {
    background: white;
    padding-bottom: 50px;
  }

  .seatDetails {
    background: white;
    display: flex;
    justify-content: space-around;
    padding: 16px 20px;

    & > div:first-child {
      width: 50%;
      border-right: 1px dashed #b2b2b2;
    }
  }

  .busDetails {
    background: white;
    padding: 16px 20px;
    display: flex;
    align-items: center;

    span {
      color: #777777;
      font-size: 11px;
    }
  }

  .divider {
    margin: 0 20px;
    border-top: 1px solid #d8d8d8;
  }

  .stationDetails {
    background: white;
    padding: 16px 20px;

    .stationHeading {
      display: flex;
      align-items: center;

      & > span {
        width: 15px;
        height: 19px;
        margin-right: 6px;
      }
    }

    .stationAddress {
      font-size: 14px;
      line-height: 24px;
    }

    .stationTime {
      color: #777777;
      line-height: 24px;
    }
  }

  .buttonContainer {
    background: white;
    padding: 16px 20px;
    display: flex;

    button {
      background: white;
      width: 50%;
      border: 0;
    }

    .marshalCallButton {
      border-right: 1px solid #c4c4c4;
      span {
        display: inline-block;
        width: 17px;
        height: 17px;
        margin-right: 10px;
      }
    }

    .supportCallButton {
      & > span:first-child {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      & > span:nth-child(2) {
        display: inline-flex;
        flex-direction: column;
        color: #7b2aff;
        align-items: flex-start;

        span {
          font-size: 9px;
          line-height: 11px;
          color: #454545;
        }
      }
    }
  }

  .mb20 {
    margin-bottom: 20px;
  }
  .offerHeader {
    margin-top: 15px;
    font-size: 13px;
    font-family: SFPROTEXT;
    font-weight: bold;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
  }

  .trayCards {
    background: white;
    border-radius: 5px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    // margin-left: auto;

    > img {
      width: 100%;
      height: 100%;
    }

    &:not(:first-child) {
      margin-top: 10px;
      width: 100%;
    }

    > * {
      font-family: "Sofia-ProSoft-Regular";
    }
  }
}

.mapContainer {
  width: 100%;
  height: 50vh;
}

.bannerContainer {
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);
}

.mb10 {
  margin-bottom: 10px;
}

.lastUpdatedAtBanner {
  padding: 10px 20px;
  text-align: center;
  background: #fff2c6;
  font-size: 13px;
  line-height: 24px;
}

.selfHelpContainer::-webkit-scrollbar {
  display: none;
}

.SOSModal {
  text-align: center;
  > * {
    font-family: "Sofia-ProSoft-Regular";
  }
  > p:nth-child(1) {
    color: #292929;

    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  > p {
    color: #454545;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > div {
    padding: 15px 0px;
    border-radius: 10px;
    background: #7b2aff;
    > span {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
.modify {
  display: flex;
  padding: 8px 7px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: 8px;
  background: rgba(123, 42, 255, 0.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.editBooking {
  position: fixed;
  width: 100%;
  max-width: 768px;
  border-radius: 20px 20px 0px 0px;
  bottom: 0;
  background: white;
  z-index: 2;
  padding-bottom: 20px;
  > .edit_header {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
  }
  > .selectable {
    display: flex;
    padding: 12px 16px 8px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    > div {
      display: flex;
      gap: 5px;
    }
  }
}
