.bannerwrapper {
  margin: 10px 0px;
  position: relative;
  border-radius: 10px;
  background: linear-gradient(
    100deg,
    #fff3cf -5.63%,
    #fffbed 45%,
    #fff5d5 97.73%
  );
  > * {
    font-family: "Sofia-ProSoft-Regular";
  }
  .Header {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div > img {
      margin-left: 6px;
    }
    > .claimBtn {
      color: white;
      border-radius: 5px;
      background: #7b2aff;

      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    }
  }
  .isApplicable {
    padding: 0px 10px 10px 10px;
    display: flex;
    align-items: center;
    > img {
      margin-right: 5px;
    }
  }
  .info {
    background: #f1e3b6;
    border-radius: 0px 0px 10px 10px;
    > div {
      width: 70%;
      padding: 05px 0px 5px 10px;
    }
  }
  > .man {
    position: absolute;
    right: 20px;
    bottom: 0;
  }
}
