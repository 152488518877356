.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .select {
    width: 100%;
    > div > div {
      display: flex;
    }
  }
  > img {
    width: 60px;
  }
  > .buttons {
    margin-top: 10px;
    display: flex;
    gap: 5px;
  }
}
