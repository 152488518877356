.overlayContainer {
  margin-top: 4rem;
  width: 100%;
  min-height: calc(100vh - 5rem);
  height: 100%;

  .header {
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    button {
      background: transparent;
      border: 0px;
    }

    span {
      font-family: "Sofia-ProSoft-Regular";
      margin-left: 5rem;
      font-size: 1.2rem;
    }
  }

  .content {
    width: 100%;
    min-height: calc(100% - 30px);
  }
}
