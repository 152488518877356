.mapContainer {
  height: 50vh;
  position: relative;
}

.trackingTray {
  position: absolute;
  // top: 45vh;
  // background: #f5f5f5;
  width: 100%;
  max-width: 768px;
  // padding: 0 2.5%;
}
.tracking {
  position: relative;
}

.hideMap {
  height: 0;
  display: none;
}

.shiftTrackingTray {
  top: 23rem;
}
