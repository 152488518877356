.item {
  width: 100%;
}

.container {
  display: flex;
  flex-wrap: wrap;
}

.container > div {
  flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
  /*demo*/
  box-shadow: 0 0 0 1px black;
  margin-bottom: 10px;
}

.callBackModal {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: white;
  border-radius: 20px 20px 0px 0px;
  padding: 0px 16px 10px 16px;
  > div:nth-child(1) {
    padding: 12px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Sofia Pro";
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
    text-align: left;
  }
  > div:nth-child(2) {
    padding: 8px 0px;
    display: flex;
    flex-direction: column;

    > span {
      color: #292929;
      font-family: "Sofia Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    > textarea {
      width: 100%;
      border-radius: 8px;
      border: 1px solid #bab8c1;
      height: 78px;
      flex-shrink: 0;
      font-family: "Sofia Pro";
      font-size: 12px;
    }
  }
  > div:nth-child(3) {
    padding: 12px 0px;
    > button {
      border-radius: 8px;
      background: #7b2aff;
      display: flex;
      height: 40px;
      padding: 12px 28px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #fff;
      font-family: "Sofia Pro";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 15.6px */
      flex: 1 0 0;
      width: 100%;
      border: 0;
    }
  }
}
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
.confirmationCallBackModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  > p:nth-child(2) {
    color: #292929;
    text-align: center;
    font-family: "Sofia Pro";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
  > p:nth-child(3) {
    color: var(--Text-Light, #454545);
    font-family: "Sofia Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > button {
    border-radius: 8px;
    background: #7b2aff;
    display: flex;
    height: 40px;
    padding: 12px 28px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #fff;
    font-family: "Sofia Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 15.6px */
    flex: 1 0 0;
    width: 100%;
    border: 0;
  }
}
