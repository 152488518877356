@media screen and (max-width: 767px) {
  .container {
    background: #7b2aff;
    overflow: hidden;
    height: 100%;
    .logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 65vh;
      text-align: center;
    }
    .loginSection {
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 20px 20px 0px 0px;
      width: 100%;
      padding: 3vh 0px;
      > .heading {
        color: #292929;
        font-family: "Montserrat-Regular";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      > .subHeading {
        color: #292929;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > div {
        > input {
          display: flex;
          padding: 0px 12px;
          height: 30px;
          width: 250px;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
          border-radius: 4px;
          border: 1px solid #e5e9f1;
          background: #fff;
        }
      }
      > button {
        display: flex;
        padding: 12px 0px;
        height: fit-content;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        width: 250px;
        border-radius: 8px;

        background: #7b2aff;

        color: #fff;

        text-align: center;
        font-family: "Sofia Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .confirmation {
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      > span {
        color: #292929;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      > p {
        color: #777;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > button {
        display: flex;
        padding: 12px 0px;
        border: none;
        justify-content: center;
        align-items: center;
        color: #fff;
        align-self: stretch;
        text-align: center;
        font-family: "Sofia Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 8px;
        background: #e94444;
      }
    }
    .success {
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      > span {
        color: #292929;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      > p {
        color: #777;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > button {
        display: flex;
        padding: 12px 0px;
        border: none;
        justify-content: center;
        align-items: center;
        color: #fff;
        align-self: stretch;
        text-align: center;
        font-family: "Sofia Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 8px;
        background: #7b2aff;
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .container {
    background: #7b2aff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    .logo {
      width: 60%;
      text-align: center;
    }
    .loginSection {
      width: 40%;
      background: #fff;
      display: flex;
      gap: 12px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      > .heading {
        color: #292929;
        font-family: "Montserrat-Regular";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      > .subHeading {
        color: #292929;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > div > input {
        display: flex;
        padding: 0px 12px;
        height: 30px;
        width: 220px;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid #e5e9f1;
        background: #fff;
      }
      > button {
        display: flex;
        padding: 12px 0px;
        height: fit-content;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: none;
        width: 220px;
        border-radius: 8px;

        background: #7b2aff;

        color: #fff;

        text-align: center;
        font-family: "Sofia Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .parentContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .confirmation {
      max-width: 765px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      > span {
        color: #292929;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      > p {
        color: #777;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > button {
        display: flex;
        padding: 12px 0px;
        border: none;
        justify-content: center;
        align-items: center;
        color: #fff;
        align-self: stretch;
        text-align: center;
        font-family: "Sofia Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 8px;
        background: #e94444;
      }
    }
    .success {
      padding: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      > span {
        color: #292929;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      > p {
        color: #777;

        text-align: center;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      > button {
        display: flex;
        padding: 12px 0px;
        border: none;
        justify-content: center;
        align-items: center;
        color: #fff;
        align-self: stretch;
        text-align: center;
        font-family: "Sofia Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 8px;
        background: #7b2aff;
      }
    }
  }
}
