.mapContainer {
    position: relative;
}

.fullScreenButton {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.currentLocation {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.mapButton {
    border: none;
    border: none;
    background: white;
    box-shadow: 2px 1px 6px #00000080;
    width: 45px;
    border-radius: 50%;
    height: 45px;
}

.hideFullScreenButton {
    visibility: hidden;
}