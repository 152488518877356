.whatsappIcon {
  width: 22px;
  margin-right: 5px;
}

.container {
  margin-top: 4rem;
  padding: 20px;
}

.container > * {
  font-family: "Sofia-ProSoft-Regular";
}

.container > h1 {
  margin-bottom: 2rem;
}

.submitContainer {
  margin-top: 2rem;
}
