.busMissedContainer {
  width: 100%;
  height: 100%;
  padding: 5rem 20px 20px;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    font-family: "Sofia-ProSoft-Regular";
  }
}
.BusMissedImage{
  max-width: 768px;
  height: auto;
}
