.userInfoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .imageContainer {
    flex-basis: 20%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .infoContainer {
    flex-basis: 75%;

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .text {
    font-size: 1rem;
  }

  .rectangle {
    border-radius: 25px;
    margin-top: 10px;
    font-size: 12px;
    background: #2f9856;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: fit-content;
    align-items: center;
    color: white;
  }
}
