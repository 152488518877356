.g-searchContainer {
  display: flex;
  align-items: center;
  margin: 0 0 20px 0;
  padding: 0 20px;
}

.g-buttonContainer {
  width: 100px;
  margin-left: 10px;
}

.card {
  border: 1px solid #f0f0f0;
}

.g-resultContainer {
  display: flex;
  margin: 20px 0 0 0;
  justify-content: space-between;
  height: 70vh;
  overflow: scroll;
}

.g-editModalContent {
  display: flex;
}

.g-editModalContentColumn1 {
  width: 250px;
  min-width: 250px;
  padding: 0 10px 0 0;
}

.g-editModalContentColumn2 {
  width: calc(100% - 250px);
  height: 500px;
}
