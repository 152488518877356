.expandDetail {
  cursor: pointer;
  border-radius: 8px;
  background: #dfedff;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  > span {
    color: #1b8ef8;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.editTable {
  display: flex;
  width: 160px;
  align-items: center;
  > img {
    padding: 0 6px;
  }
}
.Tooltip {
  border-radius: 8px;
  display: inline-flex;
  padding: 8px;
  align-items: flex-end;
  gap: 8px;
  > p:nth-child(1) {
    font-weight: 600;
  }
  p {
    margin: 0;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.statusDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  .statusActive {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 17px;
    background: #69ac39;
  }
  .statusInActive {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #dd800e;
  }
}
.listData {
  display: flex;
  padding: 12px 0px;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  align-self: stretch;
  background: #fff;
  > div {
    width: 17%;
    > span {
      color: #454545;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    > span:nth-child(2) {
      color: #454545;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  > div:nth-child(1) {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    width: 2%;
  }
  > div:nth-child(2) {
    width: 20%;
  }
  > div:nth-child(3) {
    width: 23%;
  }
  > div:nth-child(4) {
    width: 12%;
  }
  > div:nth-child(5) {
    width: 15%;
  }
  > div:nth-child(6) {
    width: 7%;
  }
  > div:nth-child(7) {
    width: 21%;
  }

  > .status {
    > span {
      color: #69ac39;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .stationDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div {
      > span {
        word-break: break-word;
        color: #454545;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      > span:nth-child(2) {
        word-break: break-word;
        color: #454545;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  > .actionTrigger {
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    > span {
      color: #dd800e;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}

.isAssignedDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  > div {
    display: flex;
    padding: 6px 0px;
    justify-content: center;
    align-items: center;
    gap: 6px;
  }
  > img {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}
.assignTrip {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  > input {
    border-radius: 8px;
    border: 1px solid #c2c2c2;
    background: #fff;
    display: flex;
    width: 150px;
    padding: 6px 12px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
  }
  > button {
    cursor: pointer;
    width: 75px;
    border-radius: 8px;
    background: #4bae4f;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 0;
    > span {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.addOptBtn {
  cursor: pointer;
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #dfedff;
  > span {
    color: #1b8ef8;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
