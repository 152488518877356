.OperatorSearch {
  display: flex;
  align-items: center;
  justify-content: space-around;

  padding: 5px 2px;
  > .selectables {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  > .dateSelect {
    gap: 4px;

    display: flex;
    align-items: center;
  }
  .searchBtn {
    display: flex;
    height: 40px;
    padding: 11px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #7b2aff;
    border: 0;
    > span {
      color: #fff;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
