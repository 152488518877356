.header {
  li {
    padding-left: 0;
    padding-top: 10px;
    position: relative;
  }
  overflow: hidden;
  z-index: 9;
  background: #fff;
  margin: auto;
  box-shadow: 0 2px 8px 0px #00000040;
  text-align: center;
  max-width: 768px;
  align-items: center;
  .tracking {
    position: fixed;
    width: 100%;
    z-index: 11;
    min-height: 50px;
    max-height: 5rem;
    max-width: 768px;
    font-size: 18px;
    text-align: center;
    color: white;
    background-color: #7b2aff;
  }

  .emergency {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    background: transparent;

    img {
      width: 30px;
    }
  }

  .etaContainer {
    padding-left: calc(2.5% + 15px);
    padding-right: calc(2.5% + 15px);
    text-align: initial;
    font-family: "Sofia-ProSoft-Regular";
    font-size: 1rem;

    > div {
      font-size: 0.9rem;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .stationDetails {
        text-overflow: ellipsis;
        max-width: 60%;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  .heading {
    padding: 14px 20px;
    background: #1c1c1c;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      overflow: hidden;
      color: #fff;
      font-family: "Sofia-ProSoft-Regular";
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    > .sos {
      border-radius: 300px;
      background: #d21923;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 5px;
        color: #fff;
        font-family: "Sofia-ProSoft-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    > .sosInitiated {
      border-radius: 300px;
      background: #292929;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      > span {
        margin-left: 5px;
        color: #fff;
        font-family: "Sofia-ProSoft-Regular";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .toggle {
    display: flex;
    padding: 3px 10px;
    align-items: flex-start;
    background: #f5f5f5;
    justify-content: center;
    gap: 10px;
    padding: 10px 0px;
    > .isNotSelected {
      gap: 10px;
      border-radius: 41px;
      border: none;
      color: #292929;
      text-align: center;
      font-family: "Sofia-ProSoft-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      display: flex;
      padding: 3px 10px;
    }
    > .isSelected {
      display: flex;
      padding: 3px 10px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 41px;
      background: #7b2aff;
      border: none;
      color: white;
      display: flex;
      padding: 3px 10px;
      align-items: flex-start;
      gap: 10px;
      border-radius: 41px;
      background: #7b2aff;
      border: none;
      color: white;
      text-align: center;
      font-family: "Sofia-ProSoft-Regular";
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }
}
