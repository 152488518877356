.qrCodeContainer {
  padding: 10px 15px;
  font-family: "Sofia-ProSoft-Regular";
  color: #7d00ff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 20px;
    margin-right: 10px;
  }
}
