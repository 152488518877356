.listData {
  display: flex;
  padding: 12px 48px;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  align-self: stretch;
  > div {
    width: 15%;
    > span {
      color: #454545;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    > span:nth-child(2) {
      color: #454545;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  > div:nth-child(1) {
    width: 2%;
  }
  > div:nth-child(4) {
    width: 23%;
  }
  .stationDetail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > div {
      > span {
        word-break: break-word;
        color: #454545;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      > span:nth-child(2) {
        word-break: break-word;
        color: #454545;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  > .actionTrigger {
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    > span {
      color: #dd800e;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  > .status > span {
    color: #69ac39;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > .remove > span {
    color: #dd800e;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
  }
}
.expandDetail {
  border-radius: 8px;
  background: #dfedff;
  cursor: pointer;
  padding: 6px 12px;
}
.assignTable {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > .isAssigned {
    display: flex;
    padding: 6px 0px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    > span {
      color: #454545;
    }
  }
  > .notAssigned {
    padding: 6px;
    gap: 10px;
    border-radius: 8px;
    background: #1b8ef8;
  }
}
.editTable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  > div {
    width: 120px;
    cursor: pointer;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #dfedff;
    > span {
      color: #1b8ef8;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.tripTable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.createModal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  span {
    color: #292929;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
  }
  .typeSelector {
    display: flex;
    gap: 16px;
    flex-direction: column;
    > div {
      display: flex;
      gap: 7px;
      flex-direction: column;
    }
  }
  > .heading {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    > span {
      color: #292929;
      font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 90.909% */
    }
    > div {
      display: flex;
      gap: 8px;
      > span {
        color: #454545;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  > .edit {
    display: flex;
    flex-direction: row;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 7px;
      gap: 7px;
    }
  }
  > .error {
    color: red;
    font-weight: 600;
  }
}
.modalMessages {
  display: flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    > span:nth-child(1) {
      color: #292929;
      font-feature-settings: "ss01" on, "cv01" on, "cv11" on;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 90.909% */
    }
    > span:nth-child(2) {
      color: #454545;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  > button {
    display: flex;
    height: 48px;
    padding: 11.5px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: #dfedff;
    border: none;
    > span {
      color: #1b8ef8;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.search {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  > .citySearch {
    display: flex;
    gap: 4px;
  }
}
.statusDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  .statusActive {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 17px;
    background: #69ac39;
  }
  .statusInActive {
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #dd800e;
  }
}

.selectedRow {
  background-color: #08425c;
}

.driverDetail {
  display: flex;
  padding: 8px;
  flex-direction: column;
  gap: 8px;
  > div {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
    > div {
      > span:nth-child(1) {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding-right: 5px;
      }
      > span:nth-child(2) {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    > img {
      width: 24px;
      height: 24px;
    }
  }
}
.expanded {
  background-color: #eff6ff; /* Choose your preferred color */
}
.expanded:hover {
  background-color: #eff6ff !important; /* Keep the expanded row color the same on hover */
}
