.container {
  margin-top: 4rem;
  padding: 20px;
  > * {
    font-family: "Sofia-ProSoft-Regular";
  }

  .imageContainer {
    width: 100%;
    height: 30vh;
    margin-bottom: 1rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .textContainer {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
