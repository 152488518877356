.marketingBar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  background: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.marketingBar::-webkit-scrollbar {
  display: none;
}

.marketingCard {
  min-width: 80vw;
  /* height: 55vw; */
  margin-right: 10px;
  background: white;
  overflow: hidden;
}

.marketingCard > .contentContainer {
  width: 100%;
  height: 41vw;
  overflow: hidden;
}

.contentContainer > img {
  width: 100%;
  border-radius: 10px;
  height: 41vw;
}

.contentContainer > iframe {
  width: 100%;
  height: 100%;
}

.marketingCard > .description {
  padding: 10px;
  text-align: center;
  word-break: break-all;
  background: white;
  height: 10vw;
  overflow: hidden;
}

.contentContainer > .hideYoutubeIframe {
  width: 0;
  height: 0;
}
