.emergencyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;

  .trackingLinkText {
    position: absolute;
    left: -9999px;
  }

  .hideSOSOptionsContainer {
    height: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .sos {
    color: white;
    background: red;
    padding: 10px 15px;
    border-radius: 10px;
  }

  .sosOptionsContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: white;
    height: 30vh;
    padding: 10px 20px;
    transition: height 0.4s;
    max-height: 170px;

    .header {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }

    .options {
      display: flex;
      align-items: center;
      font-size: 1rem;
      margin-bottom: 10px;

      img {
        width: 16px;
        margin-right: 10px;
      }
    }
  }
}
